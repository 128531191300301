<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <img src="../../assets/image/新闻资讯/资讯中部广告.png" alt="" />
    <div class="info-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/newsConsult' }"
          >返回 |
        </el-breadcrumb-item>
      </el-breadcrumb>
      <h1>企业资讯</h1>
      <h2>宜昌公交集团智慧安全管理平台正式上线运营</h2>
      <p class="intro">
        我司结合公共交通企业的日常安保业务，设计了整套智慧安全管理平台。针对企业安保部门提供了多方面的线上办公
        系统。智慧安全管理平台集合了公共交通行业的“车辆安全”“设备安全”“司机安全”“安全教育”“保险理赔”“安全事故分析”等多项维度的综合数据。
      </p>
      <div class="img-center">
        <img src="../../assets/image/新闻资讯/平台图.png" alt="" />
      </div>
      <p class="intro">
        在此基础之上，我司还创新加入了驾驶员安全手环的系统设计，结合智能手环的身体安全指标检测功能，创新性的结
        合安全管理平台，实现了数据实时回传，历史数据记录、安全指标报警以及事故数据追溯等综合设计。
      </p>
      <img id="bracelet_img" src="../../assets/image/新闻资讯/手环图.png" alt="" />
      <p class="intro">
        随着进入6月，项目已经成功交付，并稳定试运行，我司也配合公共交通行业不断升级系统，使其更加符合交通行业
        的业务场景，做到不断创新与提升。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push('/newsConsult')
    }
  }
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  display: inline-block;
}
h1 {
  display: inline-block;
  font-size: 40px;
  color: #333;
  margin: 0 0 50px 30px;
  border-bottom: 2px solid #3468d2;
}
img {
  width: 100%;
}
h2 {
  margin-top: 0 64px;
  font-size: 42px;
  color: #333;
}
.info-content {
  margin: 82px 160px 60px;
  #bracelet_img{
    width: 1135px;
    margin: 0 225px;
  }
}
.el-page-header {
  width: 200px;
  margin: 50px 0;
}
.el-page-header__content::after {
  content: '';
  display: block;
  width: 4%;
  height: 2px;
  margin-top: 8px;
  background-color: red;
}
.intro {
  margin-top: 20px;
  font-size: 30px;
  line-height: 72px;
  color: #333;
  text-indent: 2em;
}
.intro1 {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--text-color-dark-gray);
  text-indent: 2em;
  line-height: 250%;
}
.img-center {
  margin: 0 20%;
}
</style>
